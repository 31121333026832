<template>
  <div style="padding:10px;" > <!--class="home"-->
    <!-- 功能区域 -->
<!--    <div style="margin: 10px 0">
      <el-button type="primary" @click="add">新增</el-button>
    </div>-->
    <!-- 搜索区域 -->
    <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入昵称" style="width: 20%" clearable/>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" stripe border style="width: 100%" >
      <el-table-column prop="id" label="ID" sortable />
      <el-table-column prop="username" label="用户名" />
<!--      <el-table-column prop="nickName" label="昵称" />-->
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确认删除吗?" @confirm="handleDelete(scope.row.id)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="margin: 10px 0">
      <el-pagination
              v-model:currentPage="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
      >
      </el-pagination>

      <!-- 新增 弹窗提示 -->
      <el-dialog title="提示" v-model="dialogVisible" width="30%">
        <el-form :model="form" label-width="120px">
          <el-form-item label="用户名">
            <el-input v-model="form.username" style="width:80%"></el-input>
          </el-form-item>
<!--          <el-form-item label="昵称">
            <el-input v-model="form.nickName" style="width:80%"></el-input>
          </el-form-item>-->
        </el-form>
        <template #footer>
          <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "../utils/request";

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      form: {},
      dialogVisible: false,
      bookVis: false,
      search: '',
      currentPage:1,
      pageSize:10,
      total:0,
      tableData: [],
      bookList: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    showBooks(books) {
      this.bookList = books
      this.bookVis = true
    },
    load(){
      request.get("/user",{
        params:{
          pageNum:this.currentPage,
          pageSize:this.pageSize,
          search:this.search
        }
      }).then(res => {
        console.log(res)
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    add(){
      this.dialogVisible = true
      this.form={}
    },
    save(){
      if(this.form.id){ //判断是否拥有用户ID 有则更新
        request.put("/user",this.form).then(res => {
          console.log(res)
          if(res.code == '0'){
            this.$message({
              type: "success",
              message: "更新成功"
            })
          }else{
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() //刷新表格数据
          this.dialogVisible = false //关闭弹窗
        })
      }else{ //新增
        request.post("/user",this.form).then(res => {
          console.log(res)
          if(res.code == '0'){
            this.$message({
              type: "success",
              message: "新增成功"
            })
          }else{
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() //刷新表格数据
          this.dialogVisible = false //关闭弹窗
        })
      }
    },
    handleEdit(row){
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    handleDelete(id){
      console.log(id)
      request.delete("/user/"+id).then(res => {
        if(res.code === '0'){
          this.$message({
            type: "success",
            message: "删除成功"
          })
        }else{
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load() //重新加载数据
      })
    },
    handleSizeChange(pageSize){ //改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum){ //改变当前页码触发
      this.currentPage = pageNum
      this.load()
    }
  }
}
</script>

